
import Dialog from '@/components/modals/Dialog.vue'
import Spinner from '@/components/Spinner.vue'
import { appStore, currentStore, pdfStore, translationsStore } from '@/store'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import { captureException } from '@sentry/minimal'
import { orderBy } from 'lodash-es'
import { IFile, IGeneratedFile } from '@/__types__/_pro/partials/files'
import { IPDFConfig } from '@/store/modules/pdf/pdf'
import FullReport from '@/views/pdfs/FullReport.vue'
import ScreeningReport from '@/views/pdfs/ScreeningReport.vue'
import SamplesDocument from '@/views/pdfs/SamplesDocument.vue'
import DamageRelatedScreeningReport from '@/views/pdfs/DamageRelatedScreeningReport.vue'
import DocumentLine from '@/components/DocumentLine.vue'

const { configs } = pdfStore()

type IPDFRequestType = 'download' | 'preview'

interface IDocumentPDFState {
  isOpen: boolean
  openInPreview: boolean
  status: string
  feedback: string
}

interface IDocumentPDF extends IPDFConfig, IDocumentPDFState {}

// Build state object base on the report names in pdfStore
const pdfStates = Object.values(configs.all).reduce((prev, config) => {
  prev[config.reference] = {
    isOpen: false,
    openInPreview: false,
    status: 'process:idle',
    feedback: '',
  }

  return prev
}, {} as { [key: string]: IDocumentPDFState })

const trackPDFRequest = (pdfName: string, requestType: IPDFRequestType) => {
  window.gtag('event', 'report_requested', {
    event_category: pdfName,
    event_label: requestType,
  })
}

export default defineComponent({
  name: 'Documents',
  data() {
    return {
      pdfs: this.compilePDFBaseState() ?? {},
      errorText: '',
      activeFiles: [] as string[],
    }
  },
  computed: {
    ...mapState(translationsStore, ['selectedLanguage']),
    ...mapState(pdfStore, ['configs', 'generatedPDFs']),
    ...mapState(currentStore, [
      'project',
      'reportReady',
      'samples',
      'projectProVersion',
    ]),
    ...mapState(currentStore, {
      proLastUpdated: ({ project }) => {
        return project?.data.lastUpdated._seconds ?? 0
      },
      files: ({ project }): IFile[] => {
        return orderBy(
          project?.data.files ?? [],
          [(x: IFile) => x.name.toLowerCase()],
          ['asc']
        )
      },
    }),
    pdfKeys(): { documents: string[]; reports: string[] } {
      const keys = Object.keys(this.pdfs)

      return {
        documents: keys.filter((x) => x.includes('Document')),
        reports: keys.filter((x) => x.includes('Report')),
      }
    },
  },
  watch: {
    configs: {
      handler() {
        this.pdfs = this.compilePDFBaseState()
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    ...mapActions(appStore, ['setShownDialog']),
    ...mapActions(pdfStore, ['newPDFAvailable']),

    compilePDFBaseState() {
      if (!this.configs || !this.project) {
        return {}
      }

      const { isScreening } = this.project.data

      const pdfConfigs = Object.entries(this.configs.all).reduce(
        (prev, [key, config]) => {
          // If project IS a Screening, but current config IS NOT for screening
          if (isScreening && !key.includes('screeningReport')) {
            return prev
          }

          // If project IS NOT a Screening, but current config IS for screening
          if (!isScreening && key.includes('screeningReport')) {
            return prev
          }

          type IKeyMap = keyof typeof pdfStates

          // If current key IS NOT part of the predefined pdf configs/states
          if (!pdfStates[key as IKeyMap]) {
            return prev
          }

          prev[key] = {
            ...config,
            ...pdfStates[key as IKeyMap],
          }
          return prev
        },
        {} as { [key: string]: IDocumentPDF }
      )

      return pdfConfigs
    },

    getPDFConfig(pdf: string) {
      return this.pdfs[pdf]
    },

    onClickPreviewPDF(pdf: string) {
      this.onClickPDF(pdf, true)
    },

    onClickDownloadPDF(pdf: string) {
      this.onClickPDF(pdf, false)
    },

    onClickPDF(pdf: string, asPreview: boolean) {
      // Set open mode
      this.pdfs[pdf].openInPreview = asPreview

      // Track interaction
      trackPDFRequest(this.pdfs[pdf].name, asPreview ? 'preview' : 'download')

      // Due to the JS work coming up we set the initial feedback up front
      this.setPDFFeedbackAndStatus(pdf, 'process:finding-data')

      // Set target PDF as active
      this.setFileActiveState(pdf, true)

      // Forcing the PDF generation to the back of the JS que due to the
      // huge amount of work to be executed
      setTimeout(() => {
        this.pdfs[pdf].isOpen = true
      })
    },

    onPDFStatusChange(payload: { reportType: string; status: string }) {
      const { status, reportType } = payload

      if (!status) {
        return
      }

      if (status.indexOf('error:') > -1) {
        this.errorText = this.mixWB(
          'AN_ERROR_OCCURRED_WHILE_DOWNLOADING_THE_PDF_REPORT'
        )
        this.setShownDialog('downloadError')
      }

      this.setPDFFeedbackAndStatus(reportType, status)

      if (status === 'process:idle') {
        this.setFileActiveState(reportType, false)
        this.pdfs[reportType].isOpen = false
      }
    },

    setPDFFeedbackAndStatus(pdf: string, statusText: string) {
      this.pdfs[pdf].status = statusText

      switch (statusText) {
        case 'process:finding-data':
          this.pdfs[pdf].feedback = this.mixWB('GENERATING_DATA')
          break

        case 'process:creating-pdf':
          this.pdfs[pdf].feedback = this.mixWB('CREATING_REPORT')
          break

        case 'process:downloading':
          this.pdfs[pdf].feedback = this.mixWB('DOWNLOADING_REPORT')
          break

        case 'process:idle':
          this.pdfs[pdf].feedback = ''
      }
    },

    setFileActiveState(name: string, isActive: boolean) {
      if (isActive) {
        this.activeFiles.push(name)

        return
      }

      const index = this.activeFiles.findIndex((x) => x === name)

      if (index === -1) {
        return
      }

      this.activeFiles.splice(index, 1)
    },

    async onClickExistingFile(
      item: IGeneratedFile | IFile,
      downloadOnClick: boolean,
      pdf = ''
    ) {
      const { name, path } = item
      this.setFileActiveState(name, true)

      let downloadTitle = name

      if ('pdfVersion' in item && pdf) {
        trackPDFRequest(pdf, downloadOnClick ? 'download' : 'preview')
        downloadTitle = item.downloadTitle || this.pdfs[pdf].fileName
      }

      const pdfPath = this.mixGetFileUrl({
        path,
        filename: downloadTitle,
        download: downloadOnClick,
      })

      // Open in new tab
      if (!downloadOnClick) {
        window.open(pdfPath)

        this.setFileActiveState(name, false)
        return
      }

      // Download
      fetch(pdfPath)
        .then((res) => res.blob())
        .then((blob) => URL.createObjectURL(blob))
        .then((url) => {
          const downloadLink = document.createElement('a')

          document.body.appendChild(downloadLink)
          downloadLink.download = downloadTitle
          downloadLink.href = url
          downloadLink.click()

          document.body.removeChild(downloadLink)
        })
        .catch((e) => {
          captureException(e)
        })
        .finally(() => {
          this.setFileActiveState(name, false)
        })
    },
  },
  components: {
    Spinner,
    Dialog,
    DocumentLine,
    FullReport,
    ScreeningReport,
    SamplesDocument,
    DamageRelatedScreeningReport,
  },
})
