import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PDFFrontpage = _resolveComponent("PDFFrontpage")!
  const _component_PDFTableOfContents = _resolveComponent("PDFTableOfContents")!
  const _component_PDFParadigmIntro = _resolveComponent("PDFParadigmIntro")!
  const _component_PDFParadigmInspection = _resolveComponent("PDFParadigmInspection")!
  const _component_PDFParadigmValues = _resolveComponent("PDFParadigmValues")!
  const _component_PDFParadigmHandling = _resolveComponent("PDFParadigmHandling")!
  const _component_PDFParadigmRemediation = _resolveComponent("PDFParadigmRemediation")!
  const _component_PDFComments = _resolveComponent("PDFComments")!
  const _component_PDFSamples = _resolveComponent("PDFSamples")!
  const _component_PDFCategories = _resolveComponent("PDFCategories")!
  const _component_PDFPreview = _resolveComponent("PDFPreview")!

  return (_openBlock(), _createBlock(_component_PDFPreview, {
    key: _ctx.key,
    class: "ScreeningReport",
    "data-report-reference": _ctx.pdf.reference,
    referenceName: _ctx.pdf.reference,
    initFetch: _ctx.initiatePDFRequest,
    preview: _ctx.preview,
    onStatusChanged: _ctx.statusChanged
  }, {
    default: _withCtx(({ created, addAsPending, removeAsPending }) => [
      _createVNode(_component_PDFFrontpage, {
        class: "NoTOC",
        context: _ctx.pdf.reference,
        createdTimestamp: created,
        title: _ctx.pdf.title,
        onVnodeBeforeMount: ($event: any) => (addAsPending(_ctx.pdf.reference, 'section')),
        onCompleted: ($event: any) => (removeAsPending(_ctx.pdf.reference, 'section'))
      }, null, 8, ["context", "createdTimestamp", "title", "onVnodeBeforeMount", "onCompleted"]),
      _createVNode(_component_PDFTableOfContents, {
        class: "NoTOC",
        context: _ctx.pdf.reference,
        selector: _ctx.tocElementsSelector
      }, null, 8, ["context", "selector"]),
      _createVNode(_component_PDFParadigmIntro, {
        section: 1,
        context: _ctx.pdf.reference,
        onVnodeBeforeMount: ($event: any) => (addAsPending(_ctx.pdf.reference, 'section')),
        onVnodeMounted: ($event: any) => (removeAsPending(_ctx.pdf.reference, 'section'))
      }, null, 8, ["context", "onVnodeBeforeMount", "onVnodeMounted"]),
      _createVNode(_component_PDFParadigmInspection, {
        section: 2,
        context: _ctx.pdf.reference,
        onVnodeBeforeMount: ($event: any) => (addAsPending(_ctx.pdf.reference, 'section')),
        onVnodeMounted: ($event: any) => (removeAsPending(_ctx.pdf.reference, 'section'))
      }, null, 8, ["context", "onVnodeBeforeMount", "onVnodeMounted"]),
      _createVNode(_component_PDFParadigmValues, {
        section: 3,
        context: _ctx.pdf.reference,
        onVnodeBeforeMount: ($event: any) => (addAsPending(_ctx.pdf.reference, 'section')),
        onVnodeMounted: ($event: any) => (removeAsPending(_ctx.pdf.reference, 'section'))
      }, null, 8, ["context", "onVnodeBeforeMount", "onVnodeMounted"]),
      _createVNode(_component_PDFParadigmHandling, {
        section: 4,
        context: _ctx.pdf.reference,
        onVnodeBeforeMount: ($event: any) => (addAsPending(_ctx.pdf.reference, 'section')),
        onVnodeMounted: ($event: any) => (removeAsPending(_ctx.pdf.reference, 'section'))
      }, null, 8, ["context", "onVnodeBeforeMount", "onVnodeMounted"]),
      _createVNode(_component_PDFParadigmRemediation, {
        section: 5,
        context: _ctx.pdf.reference,
        onVnodeBeforeMount: ($event: any) => (addAsPending(_ctx.pdf.reference, 'section')),
        onVnodeMounted: ($event: any) => (removeAsPending(_ctx.pdf.reference, 'section'))
      }, null, 8, ["context", "onVnodeBeforeMount", "onVnodeMounted"]),
      (_ctx.reportNotes)
        ? (_openBlock(), _createBlock(_component_PDFComments, {
            key: 0,
            section: 6,
            context: _ctx.pdf.reference,
            onVnodeBeforeMount: ($event: any) => (addAsPending(_ctx.pdf.reference, 'sections')),
            onCompleted: ($event: any) => (removeAsPending(_ctx.pdf.reference, 'sections'))
          }, null, 8, ["context", "onVnodeBeforeMount", "onCompleted"]))
        : _createCommentVNode("", true),
      _createVNode(_component_PDFSamples, {
        section: _ctx.getContentIndex(7),
        context: _ctx.pdf.reference,
        onVnodeBeforeMount: ($event: any) => (addAsPending(_ctx.pdf.reference, 'sections')),
        onCompleted: ($event: any) => (removeAsPending(_ctx.pdf.reference, 'sections'))
      }, null, 8, ["section", "context", "onVnodeBeforeMount", "onCompleted"]),
      _createVNode(_component_PDFCategories, {
        section: _ctx.getContentIndex(8),
        context: _ctx.pdf.reference,
        onVnodeBeforeMount: ($event: any) => (addAsPending(_ctx.pdf.reference, 'sections')),
        onCompleted: ($event: any) => (removeAsPending(_ctx.pdf.reference, 'sections'))
      }, null, 8, ["section", "context", "onVnodeBeforeMount", "onCompleted"])
    ]),
    _: 1
  }, 8, ["data-report-reference", "referenceName", "initFetch", "preview", "onStatusChanged"]))
}