
import Spinner from '@/components/Spinner.vue'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'DocumentLine',
  emit: ['preview', 'download'],
  props: {
    tag: {
      type: String,
      default: 'li',
    },
    file: {
      type: Object as PropType<{
        name: string
        path: string
        type: string
        status: string
        size: number
        timestamp: number
      } | null>,
      default: null,
    },
    displayName: {
      type: String,
      required: true,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    documentAvailable: {
      type: Boolean,
      default: false,
    },
    status: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      states: {
        isActive: this.isActive,
        isAvailable: this.documentAvailable,
      },
    }
  },
  watch: {
    isActive(bool) {
      this.states.isActive = bool
    },
    documentAvailable(bool) {
      this.states.isAvailable = bool
    },
  },
  methods: {
    fileType(type: string) {
      return {
        className: type.includes('image') ? 'ImgIcon' : 'PDFIcon',
        svg: type.includes('image') ? 'file-img' : 'file-pdf',
      }
    },
  },
  components: { Spinner },
})
