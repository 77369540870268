import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PDFFrontpage = _resolveComponent("PDFFrontpage")!
  const _component_PDFSamples = _resolveComponent("PDFSamples")!
  const _component_PDFPreview = _resolveComponent("PDFPreview")!

  return (_openBlock(), _createBlock(_component_PDFPreview, {
    key: _ctx.key,
    class: "SamplesDocument",
    "data-report-reference": _ctx.pdf.reference,
    referenceName: _ctx.pdf.reference,
    initFetch: _ctx.initiatePDFRequest,
    preview: _ctx.preview,
    onStatusChanged: _ctx.statusChanged
  }, {
    default: _withCtx(({ created, addAsPending, removeAsPending }) => [
      _createVNode(_component_PDFFrontpage, {
        context: _ctx.pdf.reference,
        createdTimestamp: created,
        title: _ctx.pdf.title,
        onVnodeBeforeMount: ($event: any) => (addAsPending(_ctx.pdf.reference, 'section')),
        onCompleted: ($event: any) => (removeAsPending(_ctx.pdf.reference, 'section'))
      }, null, 8, ["context", "createdTimestamp", "title", "onVnodeBeforeMount", "onCompleted"]),
      _createVNode(_component_PDFSamples, {
        section: 1,
        context: _ctx.pdf.reference,
        onVnodeBeforeMount: ($event: any) => (addAsPending(_ctx.pdf.reference, 'section')),
        onCompleted: ($event: any) => (removeAsPending(_ctx.pdf.reference, 'section'))
      }, null, 8, ["context", "onVnodeBeforeMount", "onCompleted"])
    ]),
    _: 1
  }, 8, ["data-report-reference", "referenceName", "initFetch", "preview", "onStatusChanged"]))
}