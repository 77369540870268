import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3addaba3"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "StatusSpinner Generated"
}
const _hoisted_2 = { class: "StatusSpinner Default" }
const _hoisted_3 = { class: "StatusSpinner Mobile" }
const _hoisted_4 = {
  key: 1,
  class: "ReportStatus"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InlineSVG = _resolveComponent("InlineSVG")!
  const _component_Spinner = _resolveComponent("Spinner")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.tag), { class: "DocumentLine" }, {
    default: _withCtx(() => [
      (_ctx.file)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_InlineSVG, {
              class: _normalizeClass(["DocumentAvailable", [_ctx.fileType(_ctx.file.type).className]]),
              src: require(`@/assets/svg/${_ctx.fileType(_ctx.file.type).svg}.svg`)
            }, null, 8, ["class", "src"]),
            _createElementVNode("button", {
              class: "NameButton",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('preview')))
            }, [
              _createElementVNode("span", null, _toDisplayString(_ctx.displayName), 1)
            ]),
            (!_ctx.states.isActive)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  class: "DownloadButton IsActive",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('download')))
                }, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.mixWB('DOWNLOAD')), 1),
                  _createVNode(_component_InlineSVG, {
                    src: require('@/assets/svg/download.svg')
                  }, null, 8, ["src"])
                ]))
              : (_openBlock(), _createElementBlock("span", _hoisted_1, [
                  _createVNode(_component_Spinner, { size: 'auto' })
                ]))
          ], 64))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createVNode(_component_InlineSVG, {
              class: _normalizeClass(["PDFIcon", { 'DocumentAvailable': _ctx.states.isAvailable }]),
              src: require('@/assets/svg/file-pdf.svg')
            }, null, 8, ["class", "src"]),
            _createElementVNode("button", {
              class: _normalizeClass(["NameButton", { IsDisabled: !_ctx.states.isAvailable || _ctx.states.isActive }]),
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('preview')))
            }, [
              _createElementVNode("span", null, _toDisplayString(_ctx.displayName), 1)
            ], 2),
            (_ctx.states.isAvailable)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  (_ctx.states.isActive)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createElementVNode("span", _hoisted_2, [
                          _createTextVNode(_toDisplayString(_ctx.status) + " ", 1),
                          _createVNode(_component_Spinner, { size: 'auto' })
                        ]),
                        _createElementVNode("span", _hoisted_3, [
                          _createVNode(_component_Spinner, { size: 'auto' })
                        ])
                      ], 64))
                    : (_openBlock(), _createElementBlock("button", {
                        key: 1,
                        class: _normalizeClass(["DownloadButton", {IsActive: _ctx.states.isActive}]),
                        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('download')))
                      }, [
                        _createElementVNode("span", null, _toDisplayString(_ctx.mixWB('DOWNLOAD')), 1),
                        _createVNode(_component_InlineSVG, {
                          src: require('@/assets/svg/download.svg')
                        }, null, 8, ["src"])
                      ], 2))
                ], 64))
              : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.mixWB('THE_REPORT_IS_NOT_READY_YET')), 1))
          ], 64))
    ]),
    _: 1
  }))
}