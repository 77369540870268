import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-236894be"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "Documents CustomScrollbar" }
const _hoisted_2 = { class: "DocumentsContent" }
const _hoisted_3 = { class: "Heading1" }
const _hoisted_4 = { class: "PaneIntro" }
const _hoisted_5 = { class: "List" }
const _hoisted_6 = { class: "ListLabels PaneLine Disabled TitleLine" }
const _hoisted_7 = {
  key: 0,
  class: "Label"
}
const _hoisted_8 = {
  key: 1,
  class: "Label"
}
const _hoisted_9 = { class: "DocumentsList" }
const _hoisted_10 = {
  key: 0,
  class: "List"
}
const _hoisted_11 = { class: "ListLabels PaneLine Disabled TitleLine" }
const _hoisted_12 = {
  key: 0,
  class: "Label"
}
const _hoisted_13 = {
  key: 1,
  class: "Label"
}
const _hoisted_14 = { class: "DocumentsList" }
const _hoisted_15 = {
  key: 1,
  class: "List"
}
const _hoisted_16 = { class: "ListLabels PaneLine Disabled TitleLine" }
const _hoisted_17 = { class: "Label" }
const _hoisted_18 = { class: "InfoText" }
const _hoisted_19 = { class: "DocumentsList" }
const _hoisted_20 = { class: "SystemReportContainer" }
const _hoisted_21 = { class: "DownloadError" }
const _hoisted_22 = { class: "Heading1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DocumentLine = _resolveComponent("DocumentLine")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.mixWB('DOCUMENTS')), 1),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("p", null, _toDisplayString(_ctx.mixWB('MILVA_PDF_REPORT_DOWNLOAD_INFO_TEXT')), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          (_ctx.pdfKeys.reports.length === 1)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.mixWB('MILVA_REPORT')), 1))
            : (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.mixWB('MILVA_REPORTS')), 1))
        ]),
        _createElementVNode("ul", _hoisted_9, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pdfKeys.reports, (reportKey) => {
            return (_openBlock(), _createElementBlock(_Fragment, { key: reportKey }, [
              (_ctx.newPDFAvailable(reportKey))
                ? (_openBlock(), _createBlock(_component_DocumentLine, {
                    key: 0,
                    "data-line": "new pdf",
                    class: _normalizeClass({'IsDisabled': _ctx.activeFiles.length && !_ctx.activeFiles.includes(reportKey)}),
                    displayName: _ctx.getPDFConfig(reportKey).title,
                    status: _ctx.getPDFConfig(reportKey).feedback,
                    isActive: _ctx.activeFiles.includes(reportKey),
                    documentAvailable: !!_ctx.reportReady,
                    onDownload: ($event: any) => (_ctx.onClickDownloadPDF(reportKey)),
                    onPreview: ($event: any) => (_ctx.onClickPreviewPDF(reportKey))
                  }, null, 8, ["class", "displayName", "status", "isActive", "documentAvailable", "onDownload", "onPreview"]))
                : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(Object.values(_ctx.generatedPDFs[reportKey]).slice(0, 1), (item) => {
                    return (_openBlock(), _createBlock(_component_DocumentLine, {
                      key: item.timestamp,
                      "data-line": "existing pdf",
                      class: _normalizeClass({'IsDisabled': _ctx.activeFiles.length && !_ctx.activeFiles.includes(reportKey)}),
                      file: item,
                      displayName: _ctx.getPDFConfig(reportKey).title,
                      isActive: _ctx.activeFiles.includes(item.name),
                      onDownload: ($event: any) => (_ctx.onClickExistingFile(item, true, reportKey)),
                      onPreview: ($event: any) => (_ctx.onClickExistingFile(item, false, reportKey))
                    }, null, 8, ["class", "file", "displayName", "isActive", "onDownload", "onPreview"]))
                  }), 128))
            ], 64))
          }), 128))
        ])
      ]),
      (_ctx.pdfKeys.documents.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              (_ctx.pdfKeys.documents.length === 1)
                ? (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(_ctx.mixWB('MILVA_WORK_DOCUMENT')), 1))
                : (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(_ctx.mixWB('MILVA_WORK_DOCUMENTS')), 1))
            ]),
            _createElementVNode("ul", _hoisted_14, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pdfKeys.documents, (documentKey) => {
                return (_openBlock(), _createElementBlock(_Fragment, { key: documentKey }, [
                  (_ctx.newPDFAvailable(documentKey))
                    ? (_openBlock(), _createBlock(_component_DocumentLine, {
                        key: 0,
                        "data-line": "new pdf",
                        class: _normalizeClass({'IsDisabled': _ctx.activeFiles.length && !_ctx.activeFiles.includes(documentKey)}),
                        displayName: _ctx.getPDFConfig(documentKey).title,
                        status: _ctx.getPDFConfig(documentKey).feedback,
                        isActive: _ctx.activeFiles.includes(documentKey),
                        documentAvailable: !!_ctx.reportReady,
                        onDownload: ($event: any) => (_ctx.onClickDownloadPDF(documentKey)),
                        onPreview: ($event: any) => (_ctx.onClickPreviewPDF(documentKey))
                      }, null, 8, ["class", "displayName", "status", "isActive", "documentAvailable", "onDownload", "onPreview"]))
                    : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(Object.values(_ctx.generatedPDFs[documentKey]).slice(0, 1), (item) => {
                        return (_openBlock(), _createBlock(_component_DocumentLine, {
                          key: item.timestamp,
                          "data-line": "existing pdf",
                          class: _normalizeClass({'IsDisabled': _ctx.activeFiles.length && !_ctx.activeFiles.includes(documentKey)}),
                          file: item,
                          displayName: _ctx.getPDFConfig(documentKey).title,
                          isActive: _ctx.activeFiles.includes(item.name),
                          onDownload: ($event: any) => (_ctx.onClickExistingFile(item, true, documentKey)),
                          onPreview: ($event: any) => (_ctx.onClickExistingFile(item, false, documentKey))
                        }, null, 8, ["class", "file", "displayName", "isActive", "onDownload", "onPreview"]))
                      }), 128))
                ], 64))
              }), 128))
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.files.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
            _createElementVNode("div", _hoisted_16, [
              _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.mixWB('OTHER_FILES')), 1),
              _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.mixWB('RELEVANT_FILES_THAT_HAVE_BEEN_MADE_ACCESSIBLE_FOR_THE_PROJECT')), 1)
            ]),
            _createElementVNode("ul", _hoisted_19, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.files, (file) => {
                return (_openBlock(), _createBlock(_component_DocumentLine, {
                  key: file.name,
                  file: file,
                  displayName: file.name,
                  isActive: _ctx.activeFiles.includes(file.name),
                  onDownload: ($event: any) => (_ctx.onClickExistingFile(file, true)),
                  onPreview: ($event: any) => (_ctx.onClickExistingFile(file, false))
                }, null, 8, ["file", "displayName", "isActive", "onDownload", "onPreview"]))
              }), 128))
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_20, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.keys(_ctx.pdfs), (key) => {
          return (_openBlock(), _createElementBlock(_Fragment, { key: key }, [
            (_ctx.pdfs[key].isOpen)
              ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.getPDFConfig(key).component), {
                  key: 0,
                  reference: _ctx.pdfs[key].reference,
                  initFetch: true,
                  preview: _ctx.getPDFConfig(key).openInPreview,
                  onStatusChanged: _ctx.onPDFStatusChange
                }, null, 8, ["reference", "preview", "onStatusChanged"]))
              : _createCommentVNode("", true)
          ], 64))
        }), 128))
      ]),
      _createVNode(_component_Dialog, {
        name: "downloadError",
        size: "small",
        hasEasyClose: false
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_21, [
            _createElementVNode("h2", _hoisted_22, _toDisplayString(_ctx.mixWB('DOWNLOAD_OF_THE_FILE_FAILED')), 1),
            _createElementVNode("p", null, _toDisplayString(_ctx.errorText), 1),
            _createElementVNode("button", {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setShownDialog('')))
            }, [
              _createElementVNode("div", null, _toDisplayString(_ctx.mixWB('OK')), 1)
            ])
          ])
        ]),
        _: 1
      })
    ])
  ]))
}